<template>

  <div>
    <!--
    <div id="col-rev" class="content">
    <div style="float: right" >
          <el-select v-model="serviceStation" placeholder="選擇營運商(All)"   class="filter-item" clearable >
            <el-option v-for="item in serviceOptions" :key="item.id"
            :label="item.name " :value="item.id"   />
           </el-select>
    </div>
    </div> -->

    <!-- 關鍵績效指標 -->
    <el-card class="box-card" style="border-radius: 10px; ">

      <div>
        <span class="title">營運總覽</span>
      </div>
    <div>
    <el-select v-model="serviceStation" :placeholder="$t('ChargingStationS')+'('+$t('All')+')'"
        clearable size="small" class="filter-item-mid" width="140px">
        <!--
      <el-option v-for="item in stationOption" :key="item.stationId"
                :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
      <el-option v-for="item in serviceOptions" :key="item.stationId"
                :label="item.stationName" :value="item.stationId" />
    </el-select>
    </div>
      <el-row :gutter="20" class="row-con" type="flex" justify="center" style="margin-top: 1%">
        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="充電站總數" placement="bottom">
          <el-card shadow="always" class="dashboard-card" @click.native="goStation()">
            <em class="el-icon-s-shop" style="font-size: 2rem" />
            <span class="card-text">
              充電站 <br> <span class="card-num" v-if="stationNum != undefined"> {{ this.stationNum }} </span>
              <br/>
              <span style="font-size: 18px;">站</span>
            </span>
          </el-card>
        </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="充電樁總數" placement="bottom">
            <el-card shadow="always" class="dashboard-card" @click.native="goChargePoint()">
              <em class="el-icon-refrigerator" style="font-size: 2rem" />
              <span class="card-text">
               充電樁 <br> <span class="card-num" v-if="chargePointNum != undefined"> {{ this.chargePointNum }} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">樁</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="充電槍總數" placement="bottom">
            <el-card shadow="always" class="dashboard-card" @click.native="goConnector()">
              <em class="el-icon-s-unfold" style="font-size: 2rem" />
              <span class="card-text">
                充電槍 <br> <span class="card-num" v-if="connectorNum != undefined"> {{ this.connectorNum }} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">槍</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="當月消費金額" placement="bottom">
            <el-card shadow="always" class="dashboard-1" @click.native="goCost()">
              <em class="el-icon-money" style="font-size: 2rem" />
              <span class="card-text" >
                當月總金額 
                <br> <span class="card-num" v-if="costNum != undefined"> {{ this.costNum }} </span>
            <!--<br> <span class="card-num" v-if="costNum != undefined"> {{ this.costNum | currency}} </span> -->                
                <br/>
                <span style="font-size: 18px;">元</span>
                <br/>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="當月總用電量" placement="bottom">
            <el-card shadow="always" class="dashboard-1" @click.native="goPower()">
              <em class="el-icon-s-marketing" style="font-size: 2rem" />
              <span class="card-text">
                當月總用電量 
                <br> <span class="card-num" v-if="powerNum != undefined">{{this.powerNum}} </span>
                <!--<br> <span class="card-num" v-if="powerNum != undefined">{{this.powerNum == undefined? '':(this.powerNum/1000)}} </span>-->
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">度</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="當日告警訊息" placement="bottom">
            <el-card shadow="always" class="dashboard-alert" @click.native="goAlert()">
              <em class="el-icon-warning-outline" style="font-size: 2rem" />
              <span class="card-text">
                告警訊息 <br> <span class="card-num" v-if="alertNum != undefined"> {{ this.alertNum }} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">筆</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

      </el-row>
    </el-card>

    <el-card class="box-card" style="border-radius: 10px; margin-top: 15px;">
      <div>
        <span class="title">充電槍即時總覽</span>
        </div>
      <el-row :gutter="20" class="row-con" type="flex" justify="center" style="margin-top: 1%">
        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="充電槍在充電中的槍數" placement="bottom">
            <el-card shadow="always" class="dashboard-card" @click.native="goChargePointByType('Charging')">
              <em class="el-icon-connection" style="font-size: 2rem" />
              <span class="card-text">
               充電中 <br> <span class="card-num" v-if="chargingNum != undefined"> {{ this.chargingNum }} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">槍</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="充電槍待機總槍數" placement="bottom">
            <el-card shadow="always" class="dashboard-card" @click.native="goChargePointByType('Available')">
              <em class="el-icon-coffee-cup" style="font-size: 2rem" />
              <span class="card-text">
                待機中<br> <span class="card-num" v-if="availableNum != undefined"> {{ this.availableNum }} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">槍</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="離線中總槍數" placement="bottom">
            <el-card shadow="always" class="dashboard-card" @click.native="goChargePointByType('Disconnect')">
              <em class="el-icon-circle-close" style="font-size: 2rem" />
              <span class="card-text" >
                離線中 <br> <span class="card-num" v-if="outlineNum != undefined"> {{ this.outlineNum }} </span>
                <br/>
                <span style="font-size: 18px;">槍</span>
                <br/>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="當日總充電金額" placement="bottom">
            <el-card shadow="always" class="dashboard-1" @click.native="goTodayCost()">
              <em class="el-icon-money" style="font-size: 2rem" />
              <span class="card-text">
               當日金額 <br> <span class="card-num" v-if="todayBilling != undefined"> {{this.todayBilling | currency}} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">元</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>
        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="當日總用電量統計" placement="bottom">
            <el-card shadow="always" class="dashboard-1" @click.native="goTodayPower()">
              <em class="el-icon-s-marketing" style="font-size: 2rem" />
              <span class="card-text">
               當日用電量 <br> <!--<span class="card-num" v-if="todayPower != undefined">  {{this.todayPower == undefined? '':(this.todayPower/1000)}} </span>-->
               <span class="card-num" v-if="todayPower != undefined">{{this.todayPower}} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">度</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>

        <el-col :xs="12" :md="4">
          <el-tooltip class="item" effect="light" content="發生異常的槍數" placement="bottom">
            <el-card shadow="always" class="dashboard-alert" @click.native="goChargePointByType('Faulted')">
              <em class="el-icon-s-tools" style="font-size: 2rem" />
              <span class="card-text">
               異常 <br> <span class="card-num" v-if="errorNum != undefined"> {{this.errorNum}} </span>
                <br/>
                <!--(為求版面一致，不顯示的區塊)-->
                <span style="font-size: 18px;">槍</span>
              </span>
            </el-card>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card" style="border-radius: 10px; margin-top: 15px;">
      <div>
        <span class="title">充電站即時資訊</span>
        </div>
      <br/>
      <el-row>
          <el-table
            :data="stationInfo_Table" style="width: 100%; font-size: 24px; margin:auto;"
            :cell-style="{padding: '0', height: '75px'}"
             @sort-change="tableSortChange"

          >
          <el-table-column  align="center" prop="stationName" label="充電站點"  width="180" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="pointNum" label="樁數"  width="160" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="connectorNum" label="槍數"  width="160" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="availableNum" label="待機中"  width="160" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="prepareNum" label="佔用中"  width="160" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="chargingNum" label="充電中"  width="160" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="finishNum" label="充電完成"  width="160" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="outlineNum" label="離線中"  width="160" :show-overflow-tooltip="true" />
          <el-table-column  align="center" prop="errorNum" label="異常"  width="160" :show-overflow-tooltip="true" />

          </el-table>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { ALARM_STATE, PMS_REDIRECT_URL } from '@/const/const.js';
import { refreshToken, stopRefreshToken } from "@/utils/auth";
import { mixins } from '@/views/common/mixins.js';


export default {
  // 儀表板
  name: 'Dashboard',
  components: {
    //PieChart,
    //myChart2,
    //myChart,
    //BarChart,
    //LineChart,
    //StackBarChart,
    // CategoryDetail,
    // AlarmDetail,
    // AlarmDealDetail,
    // SavedDetail
  },
  mixins: [mixins],
  data() {
    return {

      serviceId: this.$store.state.currentAccount.serviceInfo.id,

      timer: '',

      serviceStation: undefined, //充電站下拉式選單
      serviceOptions:[],//充電站下拉式選單列表

      //以下為充電樁總覽參數
      stationNum: undefined, //充電站總數
      chargePointNum: undefined,//充電樁總數
      connectorNum:undefined,//充電樁總數
      costNum:undefined,//總金額
      powerNum:undefined,//總用電量
      alertNum:undefined,//告警筆數

      nowDate:undefined,
      nowMonth:undefined,



      //充電槍即時資訊參數
      chargingNum:  undefined, //充電站總數
      availableNum: undefined,//充電樁總數
      outlineNum:undefined,//充電樁總數
      errorNum:undefined,//總金額
      todayPower:undefined,
      todayBilling:undefined,

      //dashboard URL
      targetUrl:undefined,


      //表格
      stationInfo_Table:[],


    };
  },
  filters: {
    currency(value) {
        return String(value).replace(/\B(?=(\d{2})+(?!\d))/g, ',');
    }
  },
  created() {

    console.log('created dashboard');

    this.serviceId=this.$store.state.currentAccount.serviceInfo.id
    console.log('serviceId:',this.serviceId);
    this.PMS_REDIRECT_URL = PMS_REDIRECT_URL
    this.ALARM_STATE = ALARM_STATE
  this.getIniInfo()

  this.getServiceOptions()

  //refreshToken()

  },
  beforeDestroy() {
    clearInterval( this.timer );
    //stopRefreshToken();

  },
  mounted() {
    this.timer = setInterval( this.getIniInfo , 10000 );
  },

  watch: {
    'serviceStation': function (val) {
      this.getIniInfo();
     },
  },
  methods: {

    //取得營運商列表
    getServiceOptions() {
            console.log("getServiceOptions");
            this.listLoading = true
            this.targetUrl =  "/api/v1/dashboard/stationidListByserviceId?serviceId="+this.serviceId
            this.axios.get(this.targetUrl).then(res => {
              console.log(res);
            //this.serviceList = res.data
            this.serviceOptions=res.data
             })
          },

    getSummaryInfo() {

      var val = this.serviceStation
      if(val == null || val == undefined || val == "" ) val = "0";
      this.targetUrl =  "/api/v1/dashboard/dashinfo?serviceId="+this.serviceId+"&stationId="+val
      console.log('targetUrl:',this.targetUrl);


      this.axios.get(this.targetUrl).then(res => {

        console.log('res:', res.data.station);
          this.stationNum = res.data.station
          this.chargePointNum = res.data.chargePointer
          this.connectorNum = res.data.connector
          if(res.data.cost==null)
             this.costNum='0'
          else
             this.costNum = res.data.cost

          if(res.data.power==null)
             this.powerNum='0'
          else
             var intpowerNum = parseFloat(res.data.power/1000) //字串轉浮點數
             this.powerNum   = intpowerNum.toFixed(0).toString()     //四捨五入小數點第0位             
             //this.powerNum = res.data.power
         // this.powerNum = this.roundUp( res.data.power/1000)
        // this.powerNum = this.roundUp( 100)
         // this.powerNum = this.roundUp(10475678/1000)
         //this.powerNum =  res.data.power

          this.alertNum = res.data.alert

      })
    },

    roundUp ( num )
     {
    // return Math.ceil( ( num + Number.EPSILON ) * Math.pow( 10, 3 ) ) / Math.pow( 10, 3 );
   // return {num == undefined? '':(num/1000);

    },

    getIniInfo(){
      this.getSummaryInfo()
      this.getStationInfo()
      this.getCPInfo()
    },

    getStationInfo(){
      var val = this.serviceStation
      if(val == null || val == undefined || val == "" ) val = "0";
      this.targetUrl =  "/api/v1/dashboard/dashstationinfo?serviceId="+this.serviceId+"&stationId="+val
      this.axios.get(this.targetUrl).then(res => {
        console.log("dashinfo :" + this.targetUrl);
        //console.log("dashinfo :" + res.data.station);
        console.log("dashinfo :" + this.targetUrl);
        this.stationInfo_Table=res.data.content
        res.data.content.forEach(e =>
        {

        console.log(e.stationNum)

        })

      })
    },

    getCPInfo(){
      var val = this.serviceStation
      if(val == null || val == undefined || val == "" ) val = "0";
      this.targetUrl =  "/api/v1/dashboard/dashpointinfo?serviceId="+this.serviceId+"&stationId="+val
      this.axios.get(this.targetUrl).then(res => {
        console.log("dashinfo :" + this.targetUrl);
        console.log("dashinfo :" + res.data.errorNum);
        this.chargingNum=res.data.chargingNum
        this.outlineNum=res.data.outlineNum
        this.availableNum=res.data.availableNum
        this.errorNum=res.data.errorNum
       // this.todayPower=res.data.dayConnectorPower
        this.todayBilling=res.data.dayConnectorBilling
       // res.data.dayConnectorPower=1634.567
        if(res.data.dayConnectorPower==null)
             this.todayPower='0'
          else
             var intTodayPowerNum = parseFloat(res.data.dayConnectorPower/1000) //字串轉浮點數
             this.todayPower   = intTodayPowerNum.toFixed(0).toString()     //四捨五入小數點第0位  
      })
    },

    goStation(){
      this.$router.push({
        name: 'CS.station',
        params: {serviceId:this.serviceId }
      })
    },

    goChargePoint(){
      this.$router.push({
       name:'CS.point',
       params: {serviceId:this.serviceId, stationId:this.serviceStation }
      })
    },
    goConnector(){
      this.$router.push({
        name:'CS.connector',
        params: {serviceId:this.serviceId, stationId:this.serviceStation }
     })
    },

    goChargePointByType(val){
    console.log("val :" + val);
      this.$router.push({
      // name: 'CS.basic',
        name:'DB.control',
        params: {connectorState:val,serviceId:this.serviceId, stationId:this.serviceStation }
     })
    },

    goCost(){
    //  var my_date = new Date();
     // var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 2).toISOString().substring(0,10);
    //  console.log("my_date :" + my_date.toISOString().substring(0,10));
    //  console.log("first_date :" + first_date);
      this.$router.push({
        name: 'Pay.tradingrecord',
        params: {start:this.culFirstDay(), end:this.culToday(),serviceId:this.serviceId, stationId:this.serviceStation}
      })

    },

    goPower(){
     /* var my_date = new Date();
      var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 2).toISOString().substring(0,10);
      console.log("my_date :" + my_date.toISOString().substring(0,10));
      console.log("first_date :" + first_date);
     */
      this.$router.push({
        name: 'Pay.chargingrecord',
        params: {start:this.culFirstDay(), end:this.culToday(),serviceId:this.serviceId, stationId:this.serviceStation}
      })
    },

    goTodayCost(){
    /*  var my_date = new Date();
      console.log("my_date :" + my_date.toISOString().substring(0,10));
      my_date=my_date.toISOString().substring(0,10)*/
      this.$router.push({
        name: 'Pay.tradingrecord',
        params: {start:this.culToday(), end: this.culToday(),serviceId:this.serviceId, stationId:this.serviceStation}
      })

    },

    goTodayPower(){

      this.$router.push({
        name: 'Pay.chargingrecord',
        params: {start:this.culToday(), end:this.culToday(),serviceId:this.serviceId, stationId:this.serviceStation}
      })
    },

    goAlert(){
      this.$router.push({
        name: 'Ar.record',
        params: {start:this.culToday(), end: this.culToday(),serviceId:this.serviceId, stationId:this.serviceStation }
      })
    },

    culToday()
    {
      var my_date = new Date();
      return my_date.toISOString().substring(0,10)
    },

    culFirstDay(){
      var my_date = new Date();
      var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 2).toISOString().substring(0,10);
      return first_date
    }

  }
}

</script>
<style lang="scss" scoped>

.content {
  display: flex;
}

#col-rev{
  flex-direction: column;

}

.dashboard-card {
  background-color: #4ab42a;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px;
  height: auto;
  white-space: unset;
}


.dashboard-1 {
  background-color: #3b57d6;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px;
}


.dashboard-connector {
  background-color: #e990e3;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px;
}

.dashboard-alert {
  background-color: #e45454fa;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px;
}

.dashboard-card:hover {
  cursor: pointer;
}

.dashboard-card-no-click {
  background-color: #d87a80;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px;
}

.card-text {
  text-align: right;
  margin-right: 0px;
  margin-bottom: 15px;
  font-size: 24px;
  float: right;
}
.card-num {
  font-weight: bold;
  font-size: 60px;
}

.title {
  font-weight: bold;
  font-size: 30px;
  color: #08025a;
}
.detail:hover {
  cursor: pointer;
}

.card-note {
  font-size: 6px;
}

.card-hidden-note {
  font-size: 6px;
  visibility: hidden;
}
.row-con{
  display: flex;
  flex-flow: row wrap;
}
.row-con .dashboard-card{
 height: 100%;
}
.row-con .dashboard-1{
 height: 100%;
}
.row-con .dashboard-alert{
 height: 100%;
}
</style>
